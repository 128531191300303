import React, { useState, useEffect, Suspense } from 'react';
import { Spinner, Row, Col } from 'reactstrap';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { getCustomerInfo } from '../functions/api';
import { adminEmailLoginTry, changeAuthState } from '../actions/UserActions';

const LoginView = React.lazy(() => import('../containers/AuthViews/LoginView'));
const SignUpView = React.lazy(() => import('../containers/AuthViews/SignUpView'));
const PhoneVerificationView = React.lazy(() => import('../containers/AuthViews/PhoneVerificationView'));
const HomeView = React.lazy(() => import('../containers/HomeView/HomeView'));
const BookingFlowView = React.lazy(() => import('../containers/BookingFlowView/BookingFlowView'));
const ProfileView = React.lazy(() => import('../containers/ProfileView/ProfileView'));
const MyAppointments = React.lazy(() => import('../containers/MyAppointments/MyAppointments'));
const PrivacyPolicyView = React.lazy(() => import('../containers/PrivacyPolicyView/PrivacyPolicyView'));
const TermsView = React.lazy(() => import('../containers/TermsView/TermsView'));
const CancellationPolicyView = React.lazy(() => import('../containers/CancellationPolicyView/CancellationPolicyView'));

function Loading() {
  return <div />;
}

function PrivateRoute({ component: Component, authed, userProfile, phone, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} phone={phone} userProfile={userProfile} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  );
}

function PublicRoute({ component: Component, authed, goTo, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authed === false
        ? <Component {...props} />
        : <Redirect to={goTo} />}
    />
  );
}

function NavRouter(props) {
  const [isAppLoading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState({ isLoggedIn: false });
  const dispatch = useDispatch();

  useEffect(() => {
    const accessToken = window.localStorage.getItem('ClientLoginToken');
    console.log("AUTH state changed:::: ", props.isLoggedIn, accessToken);

    if (accessToken && isValidToken(accessToken)) {
      console.log("VALID TOKEN!!!");
      let decodedCode = jwt_decode(accessToken);
      console.log('decodedCode: ', decodedCode);
      let customerPhone = decodedCode.sub.split('-')[1];

      fetchUser(customerPhone); // TODO: check this
    } else {
      setUserProfile({ isLoggedIn: false });
      setLoading(false);
    }

    // fetchNotifications(user)
  }, [props.isLoggedIn]);

  function isValidToken(loginToken) {
    if (!loginToken) {
      return false;
    }

    const decoded = jwt_decode(loginToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  async function fetchUser(customerPhone) {
    let encodePhone = encodeURIComponent(customerPhone);
    let response = await getCustomerInfo(encodePhone);
    if (response.status === 200) {
      let customer = response.data.data.pageList[0];
      //check if logged in user is a customer or admin
      //TODO: update backend to get unauthorized status code for admin portal credentials login
      if (customer) {
        setUserProfile({
          isLoggedIn: true,
          customerId: customer.customerId,
          email: customer.email,
          gender: customer.gender,
          firstName: customer.customerFirstName,
          lastName: customer.customerLastName,
          image: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
          phone: customer.contactNo,
          phoneVerified: true
        });
        setLoading(false);
      } else {
        handleAdminUserlogOut();
      }
      return;
    }
  }

  // TODO: use common function for user logout
  function handleAdminUserlogOut() {
    localStorage.removeItem('ClientLoginToken');
    // changes the value of props.isLoggedIn to null
    dispatch(changeAuthState(false));
    // To show a error msg to user
    dispatch(adminEmailLoginTry(true));
  }

  if (isAppLoading) {
    return (
      <Row className="text-center align-items-center vh-100 m-0 p-0">
        <Col className="align-self-center m-0 p-0">
          <Spinner color="primary" />
        </Col>
      </Row>
    );
  } else {
    return (
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Switch>
            <PublicRoute exact path={'/login'} component={LoginView} authed={userProfile.isLoggedIn} goTo="/dashboard" />
            <PublicRoute exact path={'/signup'} component={SignUpView} authed={userProfile.isLoggedIn} goTo="/dashboard" />
            {/* <PublicRoute exact path={'/reset'} component={ForgotPWView} authed={userProfile.isLoggedIn} goTo="/dashboard" /> */}
            {/* <PublicRoute exact path={'/password/reset'} component={ResetPWView} authed={userProfile.isLoggedIn} /> */}
            <PublicRoute exact path={'/verification'} component={PhoneVerificationView} authed={userProfile.isLoggedIn} goTo="/dashboard" />
            <PublicRoute exact path={'/privacyPolicy'} component={PrivacyPolicyView} authed={userProfile.isLoggedIn} />
            <PublicRoute exact path={'/termsOfUse'} component={TermsView} authed={userProfile.isLoggedIn} />
            <PublicRoute exact path={'/cancellation-policy'} component={CancellationPolicyView} authed={userProfile.isLoggedIn} />
            {/* <Route exact path={'/covid/:slug'} component={CovidFormView} /> */}
            <PrivateRoute exact path={'/dashboard'} component={ProfileView} authed={userProfile.isLoggedIn} userProfile={userProfile} />
            <PrivateRoute path={'/bookings'} exact component={MyAppointments} authed={userProfile.isLoggedIn} userProfile={userProfile} />
            <PrivateRoute path={'/'} exact component={ProfileView} authed={userProfile.isLoggedIn} userProfile={userProfile}  />
            {/* <Route exact path="/saloons/:slug" component={BusinessDetailsView} /> */}
            <Route exact path={'/business/:slug'} render={(props) => <BookingFlowView {...props} userProfile={userProfile} />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
  };
};

export default connect(mapStateToProps)(NavRouter);
